import { ConfigProvider, ConfigProviderProps, Toast } from 'antd-mobile';
import enUS from 'antd-mobile/es/locales/en-US';
import 'normalize.css';
import ReactDOM from 'react-dom/client';
import { initMonitor } from './common/monitor.ts';
import { AxiosInterceptor } from './common/request.tsx';
import { isMobileDevice } from './common/utils.ts';
import ErrorBoundary from './components/ErrorBoundary/index.tsx';
import AuthGuard from './hoc/AuthGuard/index.tsx';
import './i18n';
import './index.less';
import Routes from './router/index.tsx';

if (import.meta.env.VITE_APP_TYPE === 'pzhan') {
  import('./index.popai.less');
}
if (import.meta.env.VITE_APP_TYPE === 'wzhan') {
  import('./index.wanzhi.less');
}
const appConfig: ConfigProviderProps = {
  locale: enUS,
};
Toast.config({ maskClickable: false });

// 如果不是移动端打开，跳转到PC站
if (!isMobileDevice() && import.meta.env.VITE_APP_PC_URL) {
  window.location.href = import.meta.env.VITE_APP_PC_URL;
}

Promise.resolve().then(() => {
  initMonitor();
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <ErrorBoundary>
    <AuthGuard>
      <ConfigProvider {...appConfig}>
        <AxiosInterceptor>
          <Routes />
        </AxiosInterceptor>
      </ConfigProvider>
    </AuthGuard>
  </ErrorBoundary>,
);
