import { GuardProvider } from '@authing/guard-react18';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { PropsWithChildren, useEffect } from 'react';

const { VITE_APP_TYPE, VITE_APP_AUTHING_APP_ID } = import.meta.env;

const AuthGuard: React.FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    if (VITE_APP_TYPE === 'wzhan') {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = 'https://cdn.authing.co/packages/guard/latest/guard.min.css';
      document.head.appendChild(link);

      const script = document.createElement('script');
      script.src = '/dfxaf3-shanghai.js';
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  if (VITE_APP_TYPE === 'pzhan') {
    return (
      <GoogleOAuthProvider clientId={import.meta.env.VITE_APP_GOOGLE_CLIENT_ID}>
        {children}
      </GoogleOAuthProvider>
    );
  }

  if (VITE_APP_TYPE === 'wzhan') {
    return (
      <GuardProvider
        appId={VITE_APP_AUTHING_APP_ID}
        config={{
          loadingComponent: (
            <img src="https://wanzhi-static.oss-cn-wulanchabu.aliyuncs.com/auth-loading.gif" />
          ),
        }}
        // 如果你使用的是私有化部署的 Authing 服务，需要传入自定义 host，如:
        // host="https://my-authing-app.example.com"

        // 默认情况下，会使用你在 Authing 控制台中配置的第一个回调地址为此次认证使用的回调地址。
        // 如果你配置了多个回调地址，也可以手动指定（此地址也需要加入到应用的「登录回调 URL」中）：
        // redirectUri="YOUR_REDIRECT_URI"
      >
        {children}
      </GuardProvider>
    );
  }
  return children;
};

export default AuthGuard;
